import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import ReactTable from 'react-table-v6';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import * as XLSX from 'xlsx';
import 'react-table-v6/react-table.css';
import ComponentCard from '../../components/ComponentCard';

const UserReactTable = () => {
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [obj, setObj] = useState({});
  const [errorModal, setErrorModal] = useState(false); // State for error modal
  const [errorMessage, setErrorMessage] = useState('');
  const [projects, setProjects] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user?.type === 'admin' || user?.type === 'management'; // Fixed condition
  const toggle = () => {
    setModal(!modal);
  };
  const toggleAddModal = () => {
    setAddModal(!addModal);
  };
  const toggleErrorModal = () => {
    setErrorModal(!errorModal); // Toggle error modal
  };
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://ooh.warburttons.com/api/userapi', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setProjects(response.data.data);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchProjects();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const id = event.target.id.value;
    const projectData = {
      email: event.target.email.value,
      name: event.target.name.value,
      type: event.target.type.value,
      mobile: event.target.mobile.value,
      zone: event.target.zone.value,
      vendor_id: event.target.vendor.value,
      area: event.target.area.value,
      company_name: event.target.company_name.value,
      company_address: event.target.company_address.value,
      gst_no: event.target.gst_no.value,
    };

    console.log('Submitting update with data:', projectData); // Log the data

    try {
      const response = await axios.post(
        `https://ooh.warburttons.com/api/update-user/${id}`,
        projectData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 201) {
        setErrorMessage('Email already registered!'); // Set error message
        setErrorModal(true); // Show error modal
      } else {
        setProjects((prevProjects) =>
          prevProjects.map((proj) => (proj.id === id ? { ...proj, ...projectData } : proj)),
        );
        setErrorMessage('Project updated successfully!');
        setErrorModal(true); // Show error modal
        setAddModal(false);
      }
      window.location.reload();
    } catch (error) {
      if (error.response) {
        console.error('Response error:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error:', error.message);
      }
    }

    setModal(false);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    const newUserData = {
      email: event.target.email.value,
      name: event.target.name.value,
      type: event.target.type.value,
      mobile: event.target.mobile.value,
      zone: event.target.zone.value,
      vendor_id: event.target.vendor.value,
      area: event.target.area.value,
      company_name: event.target.company_name.value,
      company_address: event.target.company_address.value,
      gst_no: event.target.gst_no.value,
    };

    console.log('Submitting add with data:', newUserData); // Log the data

    try {
      const response = await axios.post('https://ooh.warburttons.com/api/userapi', newUserData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 201) {
        setErrorMessage('Email already registered!'); // Set error message
        setErrorModal(true); // Show error modal
      } else {
        setProjects((prevProjects) => [...prevProjects, newUserData]);
        setErrorMessage('User added successfully!');
        setErrorModal(true); // Show error modal
        setAddModal(false);
      }
    } catch (error) {
      if (error.response) {
        console.error('Response error:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error:', error.message);
      }
    }

    setAddModal(false);
  };
  const defaultFilterMethod = (filter, row) => {
    return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
  };

  const exportToExcel = () => {
    const exportData = projects.map((project) => ({
      code: project.code,
      email: project.email,
      name: project.name,
      type: project.type,
      phone_number: project.phone_number,
      zone: project.zone,
      vendor_name: project.vendor_name,
      area: project.area,
      company_name: project.company_name,
      company_address: project.company_address,
      gst_no: project.gst_no,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    XLSX.writeFile(workbook, 'Users_data.xlsx');
  };

  const data2 = useMemo(() => {
    return projects.map((prop, key) => ({
      id: key,
      email: prop.email,
      name: (
        <Link to={`/user/${prop.id}`} style={{ textDecoration: 'none', color: '#343a40' }}>
          {prop.name}
        </Link>
      ), // Make the name clickable
      type: prop.type,
      mobile: prop.phone_number,
      zone: prop.zone,
      vendor_id: prop.vendor_id,
      area: prop.area,
      company_address: prop.company_address,
      company_name: prop.company_name,
      gst_no: prop.gst_no,

      actions: isAdmin ? (
        <div className="text-center">
          <Button
            onClick={() => {
              setObj({ id: prop.id, ...prop });
              toggle();
            }}
            color="inverse"
            size="sm"
          >
            <i className="fa fa-edit" /> <Edit />
          </Button>
        </div>
      ) : null,
    }));
  }, [projects, isAdmin]);
  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button color="primary mb-2" onClick={toggleAddModal}>
          Add User
        </Button>
        <Button color="success mt-0 m-2 " onClick={exportToExcel}>
          Export to Excel
        </Button>
      </div>
      <Modal isOpen={errorModal} toggle={toggleErrorModal}>
        <ModalHeader toggle={toggleErrorModal}></ModalHeader>
        <ModalBody>{errorMessage}</ModalBody>
      </Modal>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit User</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Input type="hidden" name="id" id="id" defaultValue={obj.id} />
            {/* Form fields */}
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="text" name="email" id="email" defaultValue={obj.email} />
            </FormGroup>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input type="text" name="name" id="name" defaultValue={obj.name} />
            </FormGroup>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input type="select" name="type" id="type" defaultValue={obj.type}>
                <option value="7">Admin</option>
                <option value="0">Client</option>
                <option value="2">Vendor</option>
                <option value="6">ZO</option>
                <option value="4">ASM</option>
                <option value="9">Outsource</option>
                <option value="3">Racce</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="mobile">Phone Number</Label>
              <Input type="text" name="mobile" id="mobile" defaultValue={obj.phone_number} />
            </FormGroup>
            <FormGroup>
              <Label for="zone">
                Zone{' '}
                <span style={{ color: 'red' }}>
                  ( Mandatory for all user except Admin & Client )
                </span>
              </Label>

              <Input type="text" name="zone" id="zone" defaultValue={obj.zone} />
            </FormGroup>
            <FormGroup>
              <Label for="vendor">
                Vendor <span style={{ color: 'red' }}> (Only mandatory for racce user )</span>
              </Label>

              <Input type="text" name="vendor" id="vendor" defaultValue={obj.vendor_id} />
            </FormGroup>
            <FormGroup>
              <Label for="area">
                Area{' '}
                <span style={{ color: 'red' }}>
                  {' '}
                  ( Mandatory for all user except Admin & Client )
                </span>
              </Label>
              <Input type="text" name="area" id="area" defaultValue={obj.area} />
            </FormGroup>
            <FormGroup>
              <Label for="company_name">
                Company Name <span style={{ color: 'green' }}> (Only for Vendor )</span>
              </Label>

              <Input
                type="text"
                name="company_name"
                id="company_name"
                defaultValue={obj.company_name}
              />
            </FormGroup>
            <FormGroup>
              <Label for="company_address">
                Company Address <span style={{ color: 'green' }}> (Only for Vendor )</span>
              </Label>
              <Input
                type="text"
                name="company_address"
                id="company_address"
                defaultValue={obj.company_address}
              />
            </FormGroup>
            <FormGroup>
              <Label for="gst_no">
                GST No <span style={{ color: 'green' }}> (Only for Vendor)</span>
              </Label>

              <Input type="text" name="gst_no" id="gst_no" defaultValue={obj.gst_no} />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="secondary" className="ml-1" onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={addModal} toggle={toggleAddModal}>
        <ModalHeader toggle={toggleAddModal}>Add New User</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleAddSubmit}>
            {/* Form fields */}
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="text" name="email" id="email" />
            </FormGroup>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input type="text" name="name" id="name" />
            </FormGroup>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input type="select" name="type" id="type">
                <option value="7">Admin</option>
                <option value="0">Client</option>
                <option value="2">Vendor</option>
                <option value="6">ZO</option>
                <option value="4">ASM</option>
                <option value="9">Outsource</option>
                <option value="3">Racce</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="mobile">Phone Number</Label>
              <Input type="text" name="mobile" id="mobile" />
            </FormGroup>
            <FormGroup>
              <Label for="zone">
                Zone{' '}
                <span style={{ color: 'red' }}>
                  ( Mandatory for all user except Admin & Client )
                </span>
              </Label>
              <Input type="text" name="zone" id="zone" />
            </FormGroup>
            <FormGroup>
              <Label for="vendor">
                Vendor <span style={{ color: 'red' }}> (Only mandatory for racce user )</span>
              </Label>
              <Input type="text" name="vendor" id="vendor" />
            </FormGroup>
            <FormGroup>
              <Label for="area">
                Area{' '}
                <span style={{ color: 'red' }}>
                  {' '}
                  ( Mandatory for all user except Admin & Client )
                </span>
              </Label>
              <Input type="text" name="area" id="area" />
            </FormGroup>
            <FormGroup>
              <Label for="company_name">
                Company Name <span style={{ color: 'green' }}> (Only for Vendor )</span>
              </Label>
              <Input type="text" name="company_name" id="company_name" />
            </FormGroup>
            <FormGroup>
              <Label for="company_address">
                Company Address <span style={{ color: 'green' }}> (Only for Vendor )</span>
              </Label>
              <Input type="text" name="company_address" id="company_address" />
            </FormGroup>
            <FormGroup>
              <Label for="gst_no">
                GST No <span style={{ color: 'green' }}> (Only for Vendor)</span>
              </Label>
              <Input type="text" name="gst_no" id="gst_no" />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                Add
              </Button>
              <Button color="secondary" className="ml-1" onClick={toggleAddModal}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>

      <ComponentCard title="Users Table">
        <ReactTable
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Name',
              accessor: 'name',
              filterable: true,
              filterMethod: (filter, row) => {
                const rowValue = row[filter.id].props.children; // Extracting the text from the Link
                return rowValue.toLowerCase().includes(filter.value.toLowerCase());
              },
            },
            {
              Header: 'Type',
              accessor: 'type',
            },
            {
              Header: 'Phone Number',
              accessor: 'mobile',
            },
            {
              Header: 'Zone',
              accessor: 'zone',
            },
            {
              Header: 'Vendor Name',
              accessor: 'vendor_id',
            },
            {
              Header: 'Area',
              accessor: 'area',
            },
            {
              Header: 'Company Name',
              accessor: 'company_name',
            },
            {
              Header: 'Company Address',
              accessor: 'company_address',
            },
            {
              Header: 'Gst No',
              accessor: 'gst_no',
            },
            {
              Header: 'Actions',
              accessor: 'actions',
              sortable: false,
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          pageSizeOptions={[10, 20, 100, 200, 500, 2500]}
          showPaginationBottom
          className="-striped -highlight"
          filterable
          data={data2}
          defaultFilterMethod={defaultFilterMethod}
        />
      </ComponentCard>
    </div>
  );
};

export default UserReactTable;
