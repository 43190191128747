import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel, Modal } from 'react-bootstrap';
import axios from 'axios';
import * as XLSX from 'xlsx';

const ProjectDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://ooh.warburttons.com/api/project/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setUser(response.data.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUser();
  }, [id]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowCarousel(true);
  };
  const baseUrl = 'https://ooh.warburttons.com/';

  const handleCloseCarousel = () => setShowCarousel(false);
  // Export Table Data to Excel
  const exportToExcel = () => {
    const tableData = [
      ['Name', user.project],
      ['Lat', user.lat],
      ['Long', user.long],
      ['Length', user.length],
      ['Width', user.width],
      ['Total', user.total],
      ['Code', user.code],
      ['Phone Number', user.mobile],
      ['Area', user.area],
      ['Zone', user.zone],
      ['State', user.state],
      ['District', user.district],
      ['City', user.city],
      ['ASM Name', user.asm_name],
      ['ZO Name', user.zo_name],
      ['Image1', `${baseUrl}${user.image1}`],
      ['Image2', `${baseUrl}${user.image2}`],
      ['Image3', `${baseUrl}${user.image3}`],
      ['Image4', `${baseUrl}${user.image4}`],
    ];

    const ws = XLSX.utils.aoa_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Project Details');
    XLSX.writeFile(wb, 'Project_Details.xlsx');
  };

  // Print the Table Data
  const printTable = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Table</title>
          <style>
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; }
            th { background-color: #f2f2f2; }
            h1 { text-align: center; }
          </style>
        </head>
        <body>
          <h1>Project Details</h1>
          <table>
            <tbody>
              <tr>
                <td><strong>Name</strong></td>
                <td>${user.project}</td>
                <td><strong>Lat</strong></td>
                <td>${user.lat}</td>
                <td><strong>Long</strong></td>
                <td>${user.long}</td>
              </tr>
              <tr>
                <td><strong>Length</strong></td>
                <td>${user.length}</td>
                <td><strong>Width</strong></td>
                <td>${user.width}</td>
                <td><strong>Total</strong></td>
                <td>${user.total}</td>
              </tr>
              <tr>
                <td><strong>Code</strong></td>
                <td>${user.code}</td>
                <td><strong>Phone Number</strong></td>
                <td>${user.mobile}</td>
                <td><strong>Area</strong></td>
                <td>${user.area}</td>
              </tr>
              <tr>
                <td><strong>Zone</strong></td>
                <td>${user.zone}</td>
                <td><strong>State</strong></td>
                <td>${user.state}</td>
                <td><strong>District</strong></td>
                <td>${user.district}</td>
              </tr>
              <tr>
                <td><strong>City</strong></td>
                <td>${user.city}</td>
                <td><strong>ASM Name</strong></td>
                <td>${user.asm_name}</td>
                <td><strong>ZO Name</strong></td>
                <td>${user.zo_name}</td>
              </tr>
              <tr>
                <td >
                  <img src="https://ooh.warburttons.com/${user.image}" alt="" width="100" height="100" />
                </td>
                <td >
                  <img src="https://ooh.warburttons.com/${user.image1}" alt="" width="100" height="100" />
                </td>
             
                <td >
                  <img src="https://ooh.warburttons.com/${user.image2}" alt="" width="100" height="100" />
                </td>
                  <td >
                  <img src="https://ooh.warburttons.com/${user.image3}" alt="" width="100" height="100" />
                </td>
                  <td >
                  <img src="https://ooh.warburttons.com/${user.image4}" alt="" width="100" height="100" />
                </td>
              </tr>
            </tbody>
          </table>
        </body>
      </html>
    `);   printWindow.onload = () => {
      const { images } = printWindow.document; // Use object destructuring here
      const totalImages = images.length;
      let loadedImages = 0;

      const imageLoaded = () => {
        loadedImages++;
        if (loadedImages === totalImages) {
          printWindow.print();
        }
      };

      for (let i = 0; i < totalImages; i++) {
        images[i].onload = imageLoaded;
        images[i].onerror = imageLoaded; // In case of error, count as loaded
      }

      if (totalImages === 0) {
        printWindow.print();
      }
    };
    printWindow.document.close();
    printWindow.print();
  };

  // history data print and excel
  const exportHistoriesToExcel = () => {
    const historiesData = user.histories.map((item) => [
      item.name,
      item.lat,
      item.long,
      item.length,
      item.width,
      item.total,
      item.code,
      item.mobile || 'N/A',
      item.area || 'N/A',
      item.zone,
      item.state,
      item.district,
      item.city,
      item.asm_name || 'N/A',
      item.zo_name || 'N/A',
      `${baseUrl}${item.image1}` || 'N/A',
      `${baseUrl}${item.image2}` || 'N/A',
      `${baseUrl}${item.image3}` || 'N/A',
      `${baseUrl}${item.image4}` || 'N/A',
    ]);

    // Include headers
    const headers = [
      'Name',
      'Lat',
      'Long',
      'Length',
      'Width',
      'Total',
      'Code',
      'Phone Number',
      'Area',
      'Zone',
      'State',
      'District',
      'City',
      'ASM Name',
      'ZO Name',
      'Image1',
      'Image2',
      'Image3',
      'Image4',
    ];

    const tableData = [headers, ...historiesData];

    const ws = XLSX.utils.aoa_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Histories');
    XLSX.writeFile(wb, 'Project_Histories.xlsx');
  };

  const printTables = () => {
    const printWindow = window.open('', '_blank');

    const historyRows = user.histories
      .map(
        (item) => `
      <tr>
        <td><strong>Project ID</strong></td>
        <td>${item.project_id}</td>
        <td><strong>Lat</strong></td>
        <td>${item.lat}</td>
        <td><strong>Long</strong></td>
        <td>${item.long}</td>
      </tr>
      <tr>
        <td><strong>Length</strong></td>
        <td>${item.length}</td>
        <td><strong>Width</strong></td>
        <td>${item.width}</td>
        <td><strong>Total</strong></td>
        <td>${item.total}</td>
      </tr>
      <tr>
        <td><strong>Code</strong></td>
        <td>${item.code}</td>
        <td><strong>Phone Number</strong></td>
        <td>${item.mobile || 'N/A'}</td>
        <td><strong>Area</strong></td>
        <td>${item.area || 'N/A'}</td>
      </tr>
      <tr>
        <td><strong>Zone</strong></td>
        <td>${item.zone}</td>
        <td><strong>State</strong></td>
        <td>${item.state}</td>
        <td><strong>District</strong></td>
        <td>${item.district}</td>
      </tr>
      <tr>
        <td><strong>City</strong></td>
        <td>${item.city}</td>
        <td><strong>ASM Name</strong></td>
        <td>${item.asm_name || 'N/A'}</td>
        <td><strong>ZO Name</strong></td>
        <td>${item.zo_name || 'N/A'}</td>
      </tr>
      <tr>
        <td>
          <img src="https://ooh.warburttons.com/${item.image}" alt="" width="100" height="100" />
        </td>
        <td>
          <img src="https://ooh.warburttons.com/${item.image1}" alt="" width="100" height="100" />
        </td>
        <td>
          <img src="https://ooh.warburttons.com/${item.image2}" alt="" width="100" height="100" />
        </td>
        <td>
          <img src="https://ooh.warburttons.com/${item.image3}" alt="" width="100" height="100" />
        </td>
        <td>
          <img src="https://ooh.warburttons.com/${item.image4}" alt="" width="100" height="100" />
        </td>
      </tr>
    `,
      )
      .join('');

   
    // Wait for the images to load before printing
    printWindow.onload = () => {
      const { images } = printWindow.document; // Use object destructuring here
      const totalImages = images.length;
      let loadedImages = 0;

      const imageLoaded = () => {
        loadedImages++;
        if (loadedImages === totalImages) {
          printWindow.print();
        }
      };

      for (let i = 0; i < totalImages; i++) {
        images[i].onload = imageLoaded;
        images[i].onerror = imageLoaded; // In case of error, count as loaded
      }

      if (totalImages === 0) {
        printWindow.print();
      }
    };
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Table</title>
          <style>
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; }
            th { background-color: #f2f2f2; }
            h1 { text-align: center; }
          </style>
        </head>
        <body>
          <h1>Project Details</h1>
          <table>
            <tbody>
              ${historyRows}
            </tbody>
          </table>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  };

  if (!user) return <div>Loading...</div>;

  return (
    <>
      <div style={{ background: '#fff', borderRadius: '10px' }}>
        <div className="p-4">
          <div className="d-flex justify-content-end">
            <button type="button" onClick={exportToExcel} className="btn btn-primary mb-3 me-2">
              Export to Excel
            </button>
            <button type="button" onClick={printTable} className="btn btn-secondary mb-3">
              Print Table
            </button>
          </div>
          <table className="table ">
            <tbody>
              <tr>
                <td width="200">
                  <h6>Name </h6>
                </td>
                <td>: {user.project}</td>

                <td width="200">
                  <h6>Lat </h6>
                </td>
                <td>: {user.lat}</td>

                <td width="200">
                  <h6>Long </h6>
                </td>
                <td>: {user.long}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Length </h6>
                </td>
                <td>: {user.length}</td>

                <td width="200">
                  <h6>Width </h6>
                </td>
                <td>: {user.width}</td>

                <td width="200">
                  <h6>Total </h6>
                </td>
                <td>: {user.total}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Code </h6>
                </td>
                <td>: {user.code}</td>

                <td width="200">
                  <h6>Phone Number </h6>
                </td>
                <td>: {user.mobile}</td>

                <td width="200">
                  <h6>Area </h6>
                </td>
                <td>: {user.area}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Zone </h6>
                </td>
                <td>: {user.zone}</td>

                <td width="200">
                  <h6>State </h6>
                </td>
                <td>: {user.state}</td>

                <td width="200">
                  <h6>District </h6>
                </td>
                <td>: {user.district}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>City </h6>
                </td>
                <td>: {user.city}</td>

                <td width="200">
                  <h6>Asm Name </h6>
                </td>
                <td>: {user.asm_name}</td>

                <td width="200">
                  <h6>Zo Name </h6>
                </td>
                <td>: {user.zo_name}</td>
              </tr>

              <tr>
                <td width="200" className="d-flex gap-2">
                  {user.image ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(0)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ''
                  )}

                  {user.image1 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(1)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image1}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ''
                  )}
                  {user.image2 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(2)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image2}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ' '
                  )}
                  {user.image3 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(3)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image3}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ''
                  )}

                  {user.image4 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(4)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image4}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <Modal className="custom-modal" show={showCarousel} onHide={handleCloseCarousel}>
            <Modal.Header className="header-m">
              <button type="button" className="cross" onClick={handleCloseCarousel}>
                x
              </button>
            </Modal.Header>
            <Modal.Body>
              <Carousel
                activeIndex={currentImageIndex}
                onSelect={(selectedIndex) => setCurrentImageIndex(selectedIndex)}
              >
                {[user.image, user.image1, user.image2, user.image3].map((img) => (
                  <Carousel.Item key={img}>
                    <img className="" src={`https://ooh.warburttons.com/${img}`} alt="Slide" />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Modal.Body>
          </Modal>
        </div>
      </div>

      <h2 style={{ marginTop: '50px' }}>History</h2>
      {user.histories.map((hist) => (
        <div style={{ background: '#fff', borderRadius: '10px', marginTop: '20px' }}>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={exportHistoriesToExcel}
                className="btn btn-primary mb-3 me-2"
              >
                Export to Excel
              </button>
              <button type="button" onClick={printTables} className="btn btn-secondary mb-3">
                Print Table
              </button>
            </div>
            <table className="table ">
              <tbody>
                <tr>
                  <td width="200">
                    <h6>Name </h6>
                  </td>
                  <td>: {hist.project}</td>

                  <td width="200">
                    <h6>Lat </h6>
                  </td>
                  <td>: {hist.lat}</td>

                  <td width="200">
                    <h6>Long </h6>
                  </td>
                  <td>: {hist.long}</td>
                </tr>

                <tr>
                  <td width="200">
                    <h6>Length </h6>
                  </td>
                  <td>: {hist.length}</td>

                  <td width="200">
                    <h6>Width </h6>
                  </td>
                  <td>: {hist.width}</td>

                  <td width="200">
                    <h6>Total </h6>
                  </td>
                  <td>: {hist.total}</td>
                </tr>

                <tr>
                  <td width="200">
                    <h6>Code </h6>
                  </td>
                  <td>: {hist.code}</td>

                  <td width="200">
                    <h6>Phone Number </h6>
                  </td>
                  <td>: {hist.mobile}</td>

                  <td width="200">
                    <h6>Area </h6>
                  </td>
                  <td>: {hist.area}</td>
                </tr>

                <tr>
                  <td width="200">
                    <h6>Zone </h6>
                  </td>
                  <td>: {hist.zone}</td>

                  <td width="200">
                    <h6>State </h6>
                  </td>
                  <td>: {hist.state}</td>

                  <td width="200">
                    <h6>District </h6>
                  </td>
                  <td>: {hist.district}</td>
                </tr>

                <tr>
                  <td width="200">
                    <h6>City </h6>
                  </td>
                  <td>: {hist.city}</td>

                  <td width="200">
                    <h6>Asm Name </h6>
                  </td>
                  <td>: {hist.asm_name}</td>

                  <td width="200">
                    <h6>Zo Name </h6>
                  </td>
                  <td>: {hist.zo_name}</td>
                </tr>

                <tr>
                  <td width="200" className="d-flex gap-2">
                    {hist.image ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(0)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ''
                    )}

                    {hist.image1 ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(1)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image1}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ''
                    )}
                    {hist.image2 ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(2)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image2}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ' '
                    )}
                    {hist.image3 ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(3)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image3}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ''
                    )}

                    {hist.image4 ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(4)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image4}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <Modal className="custom-modal" show={showCarousel} onHide={handleCloseCarousel}>
              <Modal.Header className="header-m">
                <button type="button" className="cross" onClick={handleCloseCarousel}>
                  x
                </button>
              </Modal.Header>
              <Modal.Body>
                <Carousel
                  activeIndex={currentImageIndex}
                  onSelect={(selectedIndex) => setCurrentImageIndex(selectedIndex)}
                >
                  {[hist.image, hist.image1, hist.image2, hist.image3].map((img) => (
                    <Carousel.Item key={img}>
                      <img className="" src={`https://ooh.warburttons.com/${img}`} alt="Slide" />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProjectDetails;
